h1 {
  font-size: larger;
}

h2 {
  font-size: large;
}

h3,h4,h5 {
  font-size: medium;
}

.card-body>p {
  text-align: justify;
}